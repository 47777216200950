<template>
  <div v-loading="loading">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="formData"
        :rules="formRules"
        ref="refForm"
        label-position="top"
        :status-icon="true"
      >
        <el-row :gutter="20">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
           
              <el-form-item v-if="getRoleSlug === 'superadmin'"
                :error="formErrors.admin"
                label="Admin"
                prop="admin"
                :label-width="formLabelWidth"
              >
                <el-select v-model="formData.admin" @change="handleBrokerList" placeholder="Select">
                  <el-option
                     v-for="item in listAdmins"
                      :key="item.master"
                      :label="item.name"
                      :value="item.master"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
           
            <el-form-item
              :error="formErrors.user_id"
              label="User ID"
              prop="user_id"
              :label-width="formLabelWidth"
            >   
            
              <el-select v-model="formData.user_id" placeholder="Select">
                <el-option
                  v-for="item in listDataDropdownBrokers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

             
            </el-form-item>
          </el-col>
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.notes"
              label="Notes"
              prop="notes"
              :label-width="formLabelWidth"
            >
              <el-input v-model="formData.notes" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.amount"
              label="Funds"
              prop="amount"
              :label-width="formLabelWidth"
            >
              <el-input v-model="formData.amount" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="getRoleSlug === 'admin' || getRoleSlug === 'broker'" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item
              :error="formErrors.transaction_password"
              label="Transaction Password"
              prop="transaction_password"
              :label-width="formLabelWidth"
            >
              <el-input
                v-model="formData.transaction_password"
                type="password"
                autocomplete="off"
                show-password
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog()">Cancel</el-button>
        <!-- <el-button type="info" @click="sendChildFormData()">Save</el-button>
        <el-button type="info" @click="sendChildFormData()">Save</el-button> -->

        <el-button type="info"  @click="sendChildFormData()">Credit</el-button>
        <el-button type="info" @click="sendChildFormDebitData()">Debit</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { requiredRule, numberRule } from "@/rules/all-rules";
import { fetchBrokersList } from "@/api/market-watch";
import { fetchAllAdminList, adminBrokers } from "@/api/super-admin/admin";
import { mapGetters } from "vuex";
import getRoleSlug from "@/store/app.js";


export default {
  name: "AddEditDialog",
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      formRules: {},
      formLabelWidth: "120px",
      listDataDropdownBrokers: {},
      listAdmins: {}
    };
  },
  computed: {
     ...mapGetters(["getRoleSlug"]),
    dialogTitle() {
      return this.dialogType === "create"
        ? "Add User Fund"
        : "Edit User Fund";
    },
  },
   created() {
    this.getBrokersList();
    if(getRoleSlug.state.roleSlug == 'superadmin') {
      this.getAdminList();
    }
    //this.getAdminList();
    this.formRules.user_id = requiredRule("user_id");
    this.formRules.funds = numberRule("funds", ["required"]);
    this.formRules.transaction_password = requiredRule("transaction_password");
    this.formRules.admin = requiredRule("admin");
  },
  methods: {
    getBrokersList() {
      this.loading = true;
      fetchBrokersList().then((response) => {
        this.listDataDropdownBrokers = response.data.data;
      });
    },
    handleCloseDialog() {
      this.$emit("childClose");
    },
    sendChildFormData() {
      this.loading = true;
      //   console.log(this.formData)
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          // WHEN EDIT MERGE NEW PARAMETERS
           this.formData = Object.assign(this.formData, {
            type: "broker",
          });
          this.$emit("getChildFormData", this.formData);
          this.loading = false;
        }
      });
    },
    sendChildFormDebitData() {
          this.$refs.refForm.validate((valid) => {
            if (valid) {
             
              this.formData = Object.assign(this.formData, {
                type: "customer",
              });
              this.loading = true;
              console.log("dddd  ",this.formData)
              this.$emit("getChildFormDebitData", this.formData);
              this.loading = false;

              // this.formData.transaction_password = ""
              // this.formData.notes = ""
              // this.formData.amount = ""
            }
          });
      
    },
    getAdminList() {
      fetchAllAdminList().then((response) => {
        this.listAdmins  = response.data.data;
      });
    },
    handleBrokerList(id) {
      adminBrokers(id)
        .then((response) => {
          if (response.data.success === true) {
            this.listDataDropdownBrokers = response.data.data;
          } else {
            this.flashError(response.data.message);
            this.$message.warning(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogFormVisible = true;
    }
  },
};
</script>
