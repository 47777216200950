<template>
  <div v-loading="loading" class="table-area wrapper-trading">
    <div class="table-top">
    <el-row>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Brokers</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div v-if="getRoleSlug == 'admin'" class="search-wrapper">
          <!-- <el-input
            placeholder="Search"
            v-model="search"
            class="input-with-select"
            clearable
          >
          
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearch()"
            ></el-button>
          </el-input> -->
        </div>
        <div v-else-if="getRoleSlug == 'superadmin'" class="search-wrapper">
          <!-- <el-input
            placeholder="Search"
            v-model="listQuery.search"
            class="input-with-select"
            style="width:500px;"
          >
            <el-select
              slot="prepend"
              v-model="listQuery.search_type"
              placeholder="select"
              clearable
              filterable
              style="width: 150px"
              @change="handleSelectSearchType()"
            >
              <el-option
                v-for="item in listAdmins"
                :key="item.master"
                :label="item.name"
                :value="item.master"
              />
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="sendChildFormData()"
            ></el-button>
          </el-input> -->
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper">
          <!-- <router-link to="/admin/user-add"><el-button type="primary" >Add</el-button></router-link> -->
          <el-button v-if="getRoleSlug == 'admin'" type="primary" @click="handleCreate()">Add</el-button>
          <el-button v-if="getRoleSlug == 'superadmin'" type="primary" @click="handleCreateSA()">Add</el-button>

        </div>
      </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="trading-client">
          <el-form
            :model="formData"
            ref="refForm"
            label-position="top"
            :status-icon="true"
          >
            <el-row :gutter="10">
              <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                <div class="search-wrapper">
                  <el-form-item
                    :error="formErrors.username"
                    label="Username"
                    prop="username"
                    :label-width="formLabelWidth"
                  >
                    <el-input v-model="listQuery.username" autocomplete="off"></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                <div class="search-wrapper">
                  <el-form-item
                    :error="formErrors.status"
                    label="Account status"
                    prop="status"
                    :label-width="formLabelWidth"
                  >
                     <el-select v-model="listQuery.status" placeholder="Select">
                        <el-option label="All" value="" ></el-option>
                        <el-option label="Active" value="true" ></el-option>
                        <el-option label="Inactive" value="false" ></el-option>
                      </el-select>        
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                <div class="search-wrapper">
                  <el-form-item
                    :error="formErrors.status"
                    label="Master"
                    prop="search_type"
                    :label-width="formLabelWidth"
                  >
                  <el-select
                    v-model="listQuery.search_type"
                    placeholder="select"
                  >
                  <el-option
                      v-for="item in listAdmins"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                     <!-- <el-select v-model="listQuery.status" placeholder="Select">
                        <el-option label="All" value="" ></el-option>
                        <el-option label="Active" value="true" ></el-option>
                        <el-option label="Inactive" value="false" ></el-option>
                      </el-select>         -->
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                <div class="search-wrapper">
                  <el-form-item label="" style="line-height: 40px;">
                    &nbsp;
                    </el-form-item>
                    <div class="broker-search-wrapper">
                    <el-button type="primary" @click="handleSearch()"
                      >Search</el-button
                    >
                    <el-button type="primary" @click="handleReset()"
                      >Reset</el-button
                    >
                    </div>
                  
                </div>
              </el-col>
            
            </el-row>
          </el-form>
        </div>
      </el-col>
    </el-row>
    </div>
    <Table
      tableKey="traderFunds"
      :tableConfig="config"
      :tableData="listData"
      :tableQuery="listQuery"
      :showSummary="false"
      :tablePagination="tablePagination"
      :tableActionVisibility="false"
      :tableActionViewVisibility="true"
      :tableActionDollarVisibility="true"
      :tableActionDeleteVisibility="tableActionDeleteVisibility"
      :tableActionEditVisibility="tableActionEditVisibility"
      :tableLoadingStatus.sync="loading"
       @edit="handleEdit($event)"
       @view="handleViewDetails($event)"
       @dollar="handleDollar($event)"
       @delete="handleDelete($event)"
    />
    <AddEditDialog
      :dialog-visible.sync="dialogVisible"
      :dialog-type="dialogType"
      :form-data="formData"
      :form-errors.sync="formErrors"
      @getChildFormData="handleChildFormData($event)"
      @childClose="handleChildClose()"
    />
    <UserFund
      :dialog-visible.sync="dialogVisibleUserFund"
      :dialog-type="dialogTypeUserFund"
      :form-data="formData"
      :form-errors.sync="formErrors"
      @getChildFormData="handleChildFormDataUserFund($event)"
      @getChildFormDebitData="handleChildFormDebitData($event)"
      @childClose="handleChildCloseUserFund()"
    />
  </div>
</template>

<script>
import Table from "@/components/Table/Table.vue";
import AddEditDialog from "@/views/notifications/components/AddEditDialog.vue";
import UserFund from "@/views/user-funds/components/AddEditDialog.vue";

import getRoleSlug from "@/store/app.js";
import { fetchList , destroy} from "@/api/trading-broker";
// import { _ } from "vue-underscore";
import { mapGetters } from "vuex";
import { fetchAllAdminList } from "@/api/super-admin/admin";
import { store as userFundStore, debit } from "@/api/user-fund";



export default {
  name: "ListUsers",
  components: {
    Table,
    AddEditDialog,
    UserFund
  },
  data() {
    return {
      dialogVisible: false,
      dialogType: "create",
      dialogVisibleUserFund: false,
      tableActionDeleteVisibility: false,
      tableActionEditVisibility : true,
      dialogTypeUserFund: "create",
      loading: false,
      search: "",
      listDataTemp: null,
      formData: {},
      tablePagination: {},
      formErrors: [],
      config: [
        {
          label: "User Id",
          prop: "id",
          width: "",
          className: "blueFont",
        },
        {
          label: "User Name",
          prop: "username",
          width: "",
          className: "blueFont",
        },
        // {
        //   label: "Credit Limit",
        //   prop: "credit_limit",
        //   width: "",
        //   className: "blueFont",
        // },
        {
          label: "Ledger Balanace",
          prop: "ledger_balanace",
          width: "",
          className: "blueFont",
        },
        {
          label: "Type",
          prop: "type",
          width: "",
          className: "blueFont",
        },
        // {
        //   label: "Valid Till",
        //   prop: "valid_till",
        //   width: "200",
        //   className: "blueFont",
        // },
        // {
        //   label: "Account Status",
        //   prop: "account_status",
        //   width: "200",
        //   className: "blueFont",
        // },
      ],
      listAdmins: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        search_type: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },
      listData: {
        data: {
          item: [],
        },
      },
      permission : false,
      formLabelWidth: "120px",
    };
  },
  computed: {
    ...mapGetters(["getRoleSlug", "userInfo"]),
  },
  created() {
    this.getList();
    if(getRoleSlug.state.roleSlug == 'superadmin') {
      this.tableActionDeleteVisibility = true;
      this.getAdminList();
      
    }
    
  },
  methods: {
    handleReset() {
      this.listQuery = {}
      this.listQuery.page = 1;
      this.getList();
    },
    handleDollar(data) {
      console.log(data);
      this.formData.user_id = data.id;
      this.formErrors = [];
      this.dialogVisibleUserFund = true;
      this.dialogTypeUserFund = "create";
    },
    handleChildCloseUserFund() {
      this.dialogVisibleUserFund = false;
    },
    handleChildFormDataUserFund(data) {
        this.handleStoreUserFund(data);
    },
    handleStoreUserFund(data) {
      this.loading = true;
      this.dialogVisibleUserFund = false;
      this.getList();
        userFundStore(data)
          .then((response) => {
            if (response.data.success === true) {
              this.dialogVisibleUserFund = false;
              this.flashSuccess(response.data.message);
              this.getList();
              this.loading = false;
            } else {
              this.flashError(response.data.message);
              this.formErrors = this.validationErrors(response.data.message);
            }
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
    },
       // //////////////////////
    // EMIT METHODS Debit
    // /////////////////////
    handleChildFormDebitData(data) {
      this.handleDebit(data);
    },
    handleDebit(data) {
      this.loading = true;
      console.log("gogogog   rehhh  ",data);
      this.dialogVisibleUserFund = false;
      this.getList();
      debit(data)
        .then((response) => {
          if (response.data.success === true) {
            //this.dialogVisibleUserFund = false;
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          } else {
            this.flashError(response.data.message);
            this.formErrors = this.validationErrors(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleViewDetails(data) {
        if(getRoleSlug.state.roleSlug == 'superadmin') {
          return this.$router.push("/super-admin/users/broker-view/" +data.id );
        } else {
          return this.$router.push("/" + getRoleSlug.state.roleSlug + "/users/user-view/" +data.id);
        }
      // return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/user-view/" + data.id);
    },
    handleCreateSA() {
       return this.$router.push("/super-admin/users/broker-add");
    },
     handleCreate() {
      if(this.userInfo.allowedbroker > this.userInfo.brokerCount) {
        this.permission = true;
      }
      if(this.permission) {
        return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/users/user-add");
      } else {
        // this.$message.warning('Limit Cross');
        this.$notify.error({
          title: 'Error',
          message: 'Limit Cross'
        });
      }
    },
      handleEdit(id) {
        if(getRoleSlug.state.roleSlug == 'superadmin') {
          return this.$router.push("/super-admin/users/broker-edit/" +id );
        } else {
          return this.$router.push("/" + getRoleSlug.state.roleSlug + "/users/user-edit/" +id);
        }

      // return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/user-edit/" +id );
    },
    // //////////////////
    // EMIT METHODS
    // //////////////////
    handleChildFormData(data) {
      if (data.dialog_type === "edit") {
        this.handleUpdate(data);
      } else {
        this.handleStore(data);
      }
    },
    // //////////////////
    // DELETE
    // //////////////////
    handleDelete(data) {
      console.log(data.id);
      this.$confirm(
        "This will permanently delete record. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        destroy(data.id).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          }else{
            this.flashError(response.data.message);
          }
        });
      });
    },
    handleSelectSearchType() {
      this.handleSearch()
    },
    handleSearch() {
      this.listQuery.page = 1;
      // this.listQuery.search_type = this.listQuery.search_type;
      this.getList();
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    getList() {
      this.loading = true;
      fetchList(this.listQuery).then((response) => {
        this.listDataTemp = this.listData.data.item = response.data.data.data;
        this.tablePagination = response.data.data.meta;
        this.loading = false;
      })
    },
    getAdminList() {
      fetchAllAdminList().then((response) => {
        this.listAdmins  = response.data.data;
      });
    },
    handleChildClose() {
      this.dialogVisible = false;
    },
    handleStore(data) {
      this.loading = true;
      console.log(data);
      this.dialogVisible = false;
      this.getList();
    },
  },
};
</script>
